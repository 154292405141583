<template>
  <input-form
    component-name="form-signin"
    ref="tpl_root"
    :value="initialValue"
    :submit-callback="handleSubmit"
    :success-callback="handleSuccess"
    :errors="errors"
    :autoreset-fields="false"
    :handle-app-inert="false"
    @update:value="handleUpdate"
  >
    <input-email class="w-full" />
    <input-password class="w-full" :use-confirm="false" />
    <div class="flex items-baseline gap-xs">
      <div class="text-button" v-html="$t.labels.forget_password_cta"></div>
      <btn-nav
        :title="$t.labels.click_here"
        :action="{ to: '/signin/password-recovery' }"
      />
    </div>
  </input-form>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
const { params, name: routeName } = useRoute();
const errors = ref([]);
const nuxtApp = useNuxtApp();
const tpl_root = templateRef("tpl_root");
const { setAppInert } = useGlobals();

const props = defineProps({
  email: {
    type: String,
    required: false,
  },
  referral: {
    type: String,
    required: false,
  },
  onSuccess: {
    type: Function,
    required: false,
  },
});

const initialValue = ref({
  email: props.email || "",
});

function handleUpdate(data) {}

function handleSubmit(data) {
  setAppInert(true);
  return userStore
    .login(data)
    .then((response) => {
      const res = response?.data?.value || response;

      // if (!response.data || !response.data.value) {
      //   tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
      // }
      const { status, error } = res;
      if (status == "error") {
        if (error == "not_confirmed_user") {
          tpl_root.value.handleErrors([
            nuxtApp.$t.labels.signin_user_not_confirmed,
          ]);
        } else if (error == "no_username_found") {
          tpl_root.value.handleErrors([
            nuxtApp.$t.labels.signin_no_username_found,
          ]);
        } else if (error == "invalid_credentials") {
          tpl_root.value.handleErrors([
            nuxtApp.$t.labels.signin_invalid_credentials,
          ]);
        } else {
          tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
        }
        setAppInert(false);
      } else {
      }
      return res;
    })
    .catch((error) => {
      tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
      setAppInert(false);
    });
}

function handleSuccess(data) {
  if (userStore.hasToken && props.referral) {
    userStore
      .validate()
      .then(() => {
        navigateTo(`/${params.country}/${params.locale}/${props.referral}`, {
          redirectCode: 301,
        });
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  } else if (
    userStore.hasToken &&
    /country-locale-signup-activated|country-locale-signin/.test(routeName)
  ) {
    navigateTo(
      `/${params.country}/${params.locale}/account/personal-area/moodboards`,
      {
        redirectCode: 301,
      }
    );
  } else {
    setAppInert(false);
  }

  if (props.onSuccess) {
    props.onSuccess();
  }
}
</script>
