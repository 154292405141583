import type { RouterConfig } from "@nuxt/schema";
import { useRootStore } from "@/stores/root";
export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const rootStore = useRootStore();

    if (to.path == from.path) {
      return;
    }

    const subpages = [
      "country-locale-projects-and-stories-",
      "country-locale-support-",
      "country-locale-products-find-product",
    ];

    const isSubpage = subpages.some((subpage) => {
      return to.name?.includes(subpage) && from.name?.includes(subpage);
    });

    if (isSubpage) {
      return null;
    }

    rootStore.setNeedsScrollReset(savedPosition === null);

    // if (!savedPosition) {
    //   rootStore.setNeedsScrollReset(true);
    // }

    return savedPosition;
  },
};
