<template>
  <wrapper-reveal
    data-skin="black"
    reveal="fade-from-0"
    :reveal-once="true"
    class="w-screen h-screen"
  >
    <div class="flex flex-col justify-between w-full h-full bg-skin-base">
      <header
        class="sticky top-0 left-0 flex justify-center items-center h-30 min-h-[120px] w-full border-b border-b-current text-skin-base"
      >
        <btn-mirage ref="tpl_btnMirage" class="text-skin-base" />
      </header>
      <main
        class="flex flex-col justify-center gap-y-5 w-full max-w-screen py-5xl"
      >
        <block-text-heading
          class="w-full"
          :config="{}"
          :data="{
            body: {
              overline: `${$t.labels.error} ${computedError?.statusCode}`,
              title:
                customMessage ||
                (computedError?.statusCode === 404
                  ? $t.titles.page_doesnt_exist
                  : $t.labels.something_went_wrong),
              button: {
                title: $t.labels.back_to_homepage,
                action: { to: '/' },
              },
            },
          }"
        />
      </main>
      <footer
        class="flex items-center justify-center w-full text-skin-muted p-edge"
      >
        <div
          class="text-body"
          v-html="
            `&#169; Copyright ${year} Mirage — ${$t.labels.vat_number} 00175990365`
          "
        ></div>
      </footer>
    </div>
  </wrapper-reveal>
</template>

<script setup>
const { params } = useRoute();

const { data } = await useFetch(`/api/locales?lng=${params?.locale || "en"}`);
const $t = ref(data.value);

const year = computed(() => {
  return new Date().getFullYear();
});

const error = useError({
  error: {
    message: String,
    statusMessage: String,
    message: String,
    data: Object,
  },
});

console.log("error", error.value);

const computedError = ref({ ...error.value });

const customMessage = error.value.data
  ? JSON.parse(error.value.data).customMessage
  : null;
</script>
